import { NavLink } from "react-router-dom";
import React, {useState} from "react";
import Cursor from '../components/cursor'
import * as a from "../utils/about-img"
import bg1 from '../images/About/bg-1.svg'
import avatar from '../images/Rating/avatar.png'
import bg2 from '../images/About/bg-2.svg'
import Menu from '../components/menu'
import Loader from "../components/loader";
import logo1 from '../images/Rating/logo (1).svg'
import logo2 from '../images/Rating/logo-2.svg'
import logo3 from '../images/Rating/logo-3.svg'
import logo4 from '../images/Rating/logo-5.svg'
import logo5 from '../images/Rating/logo-6.svg'
import logo6 from '../images/Rating/logo-7.svg'
import logo7 from '../images/Rating/logo-8.svg'
import logo8 from '../images/Rating/logo-9.svg'
import logo9 from '../images/Rating/logo-10.svg'
import logo10 from '../images/Rating/logo-11.png'
import logo11 from '../images/Rating/logo-12.png'
import logo12 from '../images/Rating/logo-11.svg'
import logo13 from '../images/Rating/logo-13.svg'


function Rating () {
    const [isActive, setActive] = useState(false);
    const [isActiveLoader, setActiveLoader] = useState(true);
    const [isActiveLoaderBg, setActiveLoaderBg] = useState(true);
    const [isActiveMenu, setActiveMenu] = useState(false);

    const handleMouseEnter = e => {
        setActive(true)
    }
      const handleMouseLeave = e => {
        setActive(false)
    }

    setTimeout(() => {
        setActiveLoaderBg(false)
    }, 3300 )
    setTimeout(() => {
        setActiveLoader(false)
    }, 1500 )

    return (
        <>
        <div className={isActiveLoader ? 'loader-active ' : ' loader-inactive '}>
            <div className={isActiveLoaderBg ? 'loader-container' : 'loader-container-none'}>
                <Loader ></Loader>
            </div>
        </div>
        <div className={isActiveLoaderBg || isActiveMenu ? 'page-about page-about-100vh' : 'page-about'} >
            <header className="header-about">
                <NavLink onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} to="/" className="header-main__path" >[IN]</NavLink>
            </header>
            <div className="rating">
                <div className="rating__container">
                    <h1 className="rating__title">
                    12 ОКОЛОГОСУДАРСТВЕННЫХ КОМПАНИЙ РОССИИ, ОКАЗЫВАЮЩИХ НАИБОЛЬШЕЕ ВЛИЯНИЕ НА ОТРАСЛИ ЧЕРЕЗ СОЦИАЛЬНЫЕ СЕТИ
                    </h1>
                    <p className="rating__subtitle">
                        Усиление присутствия окологосудартсвенных организаций в социальных сетях для обеспечения прозрачности работы, налаживания межорганизационных связей и партнерств, а также прямой коммуникации с широкой аудиторией – стало важным трендом коммуникационного рынка в 2022 году.
                    </p>
                    <p className="rating__subtitle">
                        Мы произвели анализ ключевых проектов, которые прошли в России и за ее пределами и имели отражение в социальных сетях. В выборку попали 98 аккаунтов окологосударственных организаций в социальных сетях Вконтакте, Одноклассники и Telegram. Анализ производился по ключевым digital-метрикам, также сравнивалось качество распространения инфоповодов от публикации на официальных сайтах до появления в СМИ и трансляции в социальных сетях, учитывалось попадание в отраслевые обзоры, медиа-вес изданий и рекламной продвижение.
                    </p>
                    <div className="rating__subtitle">
                        Итог анализа – 12 самых активных организаций с участием государства в digital-среде за 2022 год. Все они отлично используют «теорию 6 рукопожатий»; за текущий год эти компании так или иначе осуществляли связь друг с другом, причем делали это через активные упоминания и совместные проекты. 
                    </div>
                    <div className="rating__ceo">
                        <div className="rating__ceo-avatar">
                            <img src={avatar} alt="" className="rating__ceo-img" />
                            <h3 className="rating__ceo-title">
                                Владимир Балашов
                            </h3>
                            <p className="rating__ceo-subtitle">
                                CEO агенства HERO[IN]MARKETING
                            </p>
                        </div>
                        <div className="rating__ceo-texts">
                            <p className="rating__ceo-text">
                                Традиционный PR за последний год претерпел серьезные изменения, сейчас все чаще мы говорим именно про Digital-PR и приятно видеть, что организации с государственным участием не только не игнорируют актуальные тренды, но и активно внедряют в свою работу новые инструменты и подходы, показывая тем самым готовность к кооперации и ориентацию на прозрачный результат
                            </p>
                        </div>
                    </div>
                    <div className="rating__table">
                        <table>
                            <tr><th>Название компании</th><th>Отрасль</th></tr>
                            <tr><td>Аэрофлот</td><td>Авиация</td></tr>
                            <tr><td>Россети</td><td>Энергетика</td></tr>
                            <tr><td>Почта России</td><td>Почтовая связь</td></tr>
                            <tr><td>Ростелеком</td><td>Телекоммуникации, связь</td></tr>
                            <tr><td>ВЭБ.РФ</td><td>Экономика, инновации</td></tr>
                            <tr><td>АВТОВАЗ</td><td>Автомобильная промышленность</td></tr>
                            <tr><td>Фонд «Сколково»</td><td>Технологии, предпринимательство</td></tr>
                            <tr><td>Ростех</td><td>Авиастроение, машиностроение, радиоэлектроника</td></tr>
                            <tr><td>РЭЦ</td><td>Экспорт</td></tr>
                            <tr><td>РЖД</td><td>Транспорт, логистика, автодилеры</td></tr>
                            <tr><td>РФРИТ</td><td>Информационные технологии</td></tr>
                            <tr><td>ОЭЗ Иннополис</td><td>Архитектура</td></tr>
                        </table>
                    </div>
                    <div className="rating__blocks">
                        <div className="rating__block">
                            <img src={logo1} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.aeroflot.ru/ru-ru" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/aeroflot_official" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/aeroflot" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/moyaeroflot" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Одна из старейших авиакомпаний мира и один из наиболее узнаваемых российских брендов. Пятый год подряд удерживает звание сильнейшего авиационного бренда в мире по данным независимого агентства Brand Finance.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: вышли в Telegram сразу после блокировки «Роскомнадзор», начали PR международных рейсов из Сочи через социальные сети, раз в неделю занимали новые «эшелоны высоты» по подписчикам, сделав упор на живой контент, ситуативные посты и минимум дизайнерских визуалов.
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo2} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.rosseti.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/rosseti_official" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/rosseti" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/rosseti/" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Крупнейшая в России и мире энергетическая компания, обеспечивающая передачу и распределение электроэнергии.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: создали Telegram-канал в феврале 2020 года, 77% подписчиков читают посты канала (ERR), компания подробно освещает свою деятельность с использованием живых фото, создают «маски» и вовлекают подписчиков в контент. 
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo3} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.pochta.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/napochte" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/russianpost" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/ruspostofficial" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Доставляют в любой город или село письма, посылки, деньги и товары не только в физическом, но и цифровом виде, используя Яндекс.Роверы.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Самая активная компания по общению с аудиторией - более 61 000 комментарие во ВКонтакте за 2022 год, а число просмотров постов превышает 30 млн., 69% подписчиков в Telegram-канале читают посты канала (ERR). Произведено разделение контента темам, форматам и визуалам в разных социальных сетях: ВКонтакте + Одноклассники и в Telegram. 
                            </p>
                        </div>                        
                        <div className="rating__block">
                            <img src={logo12} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.company.rt.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/rostelecomofficial" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/rostelecom" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/rostelecom.official" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Главный российский провайдер цифровых услуг и сервисов.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Обеспечивают продвижение сервисов компании через примеры и истории успеха с партнерами, устраивают коллаборации в социальных сетях и проводят конкурсы, основной упор делается на цифровом контенте, 60% подписчиков читают посты в Telegram-канале. Одним из центральных направлений в социальных сетях стали игровые стримы.
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo13} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://veb.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/razvivaemrf" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/vebrf" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/vebrf" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Государственная корпорация развития, которая в партнёрстве с коммерческими банками занимается финансированием масштабных проектов по всей России.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Организуют коллаборации с VK и RuTube в социальных сетях во время участия в крупнейших мероприятиях страны (ПМЭФ, ВЭФ и др.). Проводят стримы, привлекают федеральных и региональных лидеров мнений. Взаимодействуют с аудиторией от Калининграда до Владивостока, разделяя контент под каналы коммуникации: Telegram – оперативный контент, новости и экспертные обзоры, ВКонтакте и Одноклассники – взаимодействие с регионами. 
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo5} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.lada.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> ,  <a href="https://vk.com/lada" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/lada" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Крупнейший производитель легковых автомобилей в Российской Федерации
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Создают рекламные ролики под разные сегменты своей целевой аудитории, выпускают ситуативный контент, проводят конкурсы. Первыми создали самую большую контролируемую сеть сообществ дилерских центров в социальных сетях с единым форматом KPI и стилистикой. 
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo6} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://sk.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/skolkovolive" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/fondskolkovo" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/fondskolkovo" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Точка притяжения технологий будущего, самое большое место для роста стартапов в России.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Группы в социальных сетях с самым большим количеством подписчиков среди всех организаций развития РФ (более 90 000), создали и активно ведут Telegram-канала с 2018 года, более 50% подписчиков читают посты канала, реализуют первичную коммуникацию с инновационными предпринимателями в offline-среде и подключают на цифровые сервисы и социальные сети.
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo7} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://rostec.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/rostecru" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/rostec_ru" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/group/62392050647140" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Традиции отечественной инженерной мысли и инновации в производстве, главным образом, в оборонной и высокотехнологичной промышленности.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Доступным языком рассказывают о масштабных проектах, технике, делятся живыми фотографиями и используют формат Stories. Разделяют контент по каналам коммуникации, делают упор на видео и инфографике, делятся уникальными кадрами с испытаний техники, создали сеть каналов коммуникации по своим дочерним организациям, еще больше разделяя контент. 66% подписчиков читают посты в Telegram-канале (ERR).
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo8} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.exportcenter.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://vk.com/exportcenter" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://t.me/rusexportnews" target="_blank" rel="noopener noreferrer">Telegram</a> 
                            </p>
                            <p className="rating__block-text">
                                О компании: Повышает экспортный потенциал российских предпринимателей через полный спектр услуг от проведения первичных консультаций до помощи в оформлении экспортных сделок.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: На регулярной основе освещают все события российского экспорта: от новостей по отраслям до успеха отечественных компаний, среди всех организаций развития лидируют по суммарному числу постов за год (более 2,5 тыс.), создали работающую сеть Центров поддержки экспорта в регионах через Telegram.
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo9} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.rzd.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/telerzd" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/rzd_official" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/rzd.official" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Оператор инфраструктуры российской сети железных дорог, одна из крупнейших в мире транспортных компаний.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Разделяют контент в зависимости от площадок, обеспечивают взаимодействие с b2b и b2c аудиторией. В контенте делятся живыми фотографиями и реальными историями подписчиков, в основе контента лежит возможность путешествий и упоминание партнеров. Компанией запустила отдельную «газету», где выходит ситуативный контент, регулярно устраивают конкурсы для подписчиков и публикуется образовательный контент. 
                            </p>
                        </div>
                        <div className="rating__block">
                            <img src={logo10} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://rfrit.ru/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/rfrit" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/rfrit" target="_blank" rel="noopener noreferrer">ВКонтакте</a>, <a href="https://ok.ru/group/70000000699512" target="_blank" rel="noopener noreferrer">Одноклассники</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Фонд развития информационных технологий – гранты стартапам, поддержка разработки и продвижения российского ПО.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Лидеры рейтинга вовлеченности аудитории среди организаций развития, запустили собственный продуктовый подкаст в социальных сетях, проводят регулярные эфиры с Минцифры, оперативно транслируют новости компании доступным языком. Создали отдельный Telegram-канал для обсуждения мер грантовой поддержки с представителями ИТ-компаний.
                            </p>
                        </div>

                        <div className="rating__block">
                            <img src={logo11} alt="" className="rating__block-img" />
                            <p className="rating__block-text">
                                Где следить: <a href="https://www.innopolis.com/" target="_blank" rel="noopener noreferrer">сайт компании</a> , <a href="https://t.me/innopolistg" target="_blank" rel="noopener noreferrer">Telegram</a> , <a href="https://vk.com/innopolis" target="_blank" rel="noopener noreferrer">ВКонтакте</a>
                            </p>
                            <p className="rating__block-text">
                                О компании: Город высоких технологий в Республике Татарстан.
                            </p>
                            <p className="rating__block-text rating__block-text-small">
                                Как работают в Digital: Привлекают стартапы и предпринимателей через сервисы целого города, показывают комфорт и высокое качество среды, общаются с подписчиками на одном языке (в зависимости от канала коммуникации), строят VR-библиотеки знаний, которые демонстрируют через механизмы соц. сетей, 77% подписчиков читают посты Telegram-канала (ERR).
                            </p>
                        </div>
                    </div>
                    <div className="rating__special">
                       <b>Примечание:</b> указанная последовательность компаний не связана с их положением в топ 12 рейтинга.
                    </div>
                </div>

            </div>
            <div className="rating__profile">
                <div className="rating__profile-container">
                    <h4 className="rating__profile-title">
                        Профиль агентства HERO[IN]MARKETING:
                    </h4>
                    <p className="rating__profile-text">
                        Агентство HERO[IN]MARKETING более 6 лет помогает технологическим компаниям в запуске новых продуктов, построении и реализации маркетинговой стратегии, продвижении в цифровом пространстве. Среди клиентов Агенства: IT-корпорации Kaspersky и VMware, центры инновационного развития – Сколково и Агентство Инноваций Москвы, образовательные EdTech-сервисы – Яндекс.Учебник и IU.RU, организации поддержки предпринимателей – РЭЦ, МЭЦ и Департамент предпринимательства и инновационного развития г. Москвы.
                    </p>
                </div>
            </div>
            <img src={bg1} alt="" className="about-bg1" />
            <img src={bg2} alt="" className="about-bg2" />
        </div>
        <Menu setActiveMenu={setActiveMenu} isActiveMenu={isActiveMenu}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></Menu>
        <Cursor isActives={isActive}></Cursor> 
        </>
    )
}

export default Rating