

function Loader() {

    return(
        <>
                  
                    <div class="loader"></div>
                
        </>
    )
}

export default Loader